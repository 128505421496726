import { ROUTES } from 'enumsV2';

export interface PageTitle {
  route: string;
  baseRoute?: string;
  title: string;
}

export const pageTitles: PageTitle[] = [
  { route: ROUTES.HOME, title: 'Home' },
  {
    route: ROUTES.MY_CAMPAIGNS,
    baseRoute: ROUTES.MY_CAMPAIGNS,
    title: 'Email Campaigns'
  },
  {
    route: ROUTES.COMPANY_CAMPAIGNS,
    baseRoute: ROUTES.COMPANY_CAMPAIGNS,
    title: 'Company Campaigns'
  },
  {
    route: ROUTES.RESOURCES,
    baseRoute: ROUTES.RESOURCES,
    title: 'Resources'
  },
  {
    route: ROUTES.WORKSPACES_CONTACTS_GROUPS,
    baseRoute: ROUTES.WORKSPACES_CONTACTS_GROUPS,
    title: 'Contacts & Groups'
  },
  {
    route: ROUTES.WORKSPACES_FILES,
    baseRoute: ROUTES.WORKSPACES_FILES,
    title: 'My Files'
  },
  {
    route: ROUTES.WORKSPACES_PRESENTATIONS,
    baseRoute: ROUTES.WORKSPACES_PRESENTATIONS,
    title: 'Presentations'
  },
  {
    route: ROUTES.WORKSPACES_TEMPLATES,
    baseRoute: ROUTES.WORKSPACES_TEMPLATES,
    title: 'Templates'
  },
  {
    route: ROUTES.WORKSPACES_SNIPPETS,
    baseRoute: ROUTES.WORKSPACES_SNIPPETS,
    title: 'Snippets'
  },
  {
    route: ROUTES.WORKSPACES_DIGITAL_ROOMS,
    baseRoute: ROUTES.WORKSPACES_DIGITAL_ROOMS,
    title: 'Digital Rooms'
  },
  {
    route: ROUTES.OUTBOX,
    baseRoute: ROUTES.OUTBOX,
    title: 'Outbox'
  },
  {
    route: ROUTES.VET_SUITE,
    baseRoute: ROUTES.VET_SUITE,
    title: 'VetSuite'
  },
  {
    route: ROUTES.DIGITAL_SHOWROOM,
    baseRoute: ROUTES.DIGITAL_SHOWROOM,
    title: 'VetSuite Digital Showroom'
  },
  {
    route: ROUTES.DIGITAL_SHOWROOM_RFQ,
    baseRoute: ROUTES.DIGITAL_SHOWROOM_RFQ,
    title: 'Equipment Request for Quote'
  },
  {
    route: ROUTES.PRODUCTS,
    baseRoute: ROUTES.PRODUCTS,
    title: 'Catalog'
  },
  {
    route: ROUTES.AUTO_RFQ,
    baseRoute: ROUTES.AUTO_RFQ,
    title: 'Auto RFQ'
  },
  {
    route: ROUTES.ORDERS,
    baseRoute: ROUTES.ORDERS,
    title: 'Order'
  },
  {
    route: ROUTES.REPORTING_DETAILS,
    baseRoute: ROUTES.REPORTING,
    title: 'Reporting'
  },
  {
    route: ROUTES.ADMIN_RESOURCES,
    baseRoute: ROUTES.ADMIN_RESOURCES,
    title: 'Manage Resources'
  },
  {
    route: ROUTES.ADMIN_USERS,
    baseRoute: ROUTES.ADMIN_USERS,
    title: 'Manage Users'
  },
  {
    route: ROUTES.ADMIN_CAMPAIGNS,
    baseRoute: ROUTES.ADMIN_CAMPAIGNS,
    title: 'Manage Campaigns'
  },
  {
    route: ROUTES.HOME_SCREEN,
    baseRoute: ROUTES.HOME_SCREEN,
    title: 'Manage Home screen'
  },
  {
    route: ROUTES.EMAIL_QUEUE,
    baseRoute: ROUTES.EMAIL_QUEUE,
    title: 'Queued Emails'
  },
  {
    route: ROUTES.SETTINGS,
    baseRoute: ROUTES.SETTINGS,
    title: 'Settings'
  }
];

export const getPageTitle = (pathname: string): string =>
  pageTitles.find(
    el => el.route === pathname || pathname.startsWith(el.baseRoute)
  )?.title || 'Setvi';
