export enum RFQApi {
  GetProductsByText = '/api/rfq/upload-free-text',
  GetProductsByUrl = '/api/rfq/upload-url-html',
  GetJobIdByFile = '/api/rfq/upload',
  GetJobIdByUrl = '/api/rfq/upload-url',
  GetProductsById = '/api/rfq/result',
  GetJobStatus = '/api/rfq/status',
  GetProductDetails = '/api/competitor-product/summary',
  GetProductsByImageUrl = '/api/rfq/upload-image-url'
}

export enum AutoRFQApi {
  Create = '/rfq',
  GetAll = '/rfq/',
  SaveItems = '/rfq/items',
  DeleteSingle = '/rfq/:id',
  GenerateCSV = '/rfq/csv/:id'
}
